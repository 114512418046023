<template>
  <div>
    <v-btn
      v-if="value && scopeModes[0] !== 'global'"
      icon
      small
      @click.stop="newMessage()"
    >
      <icon-tooltip
        small="small"
        :icon-name="'i.Email'"
        :tooltip-text="$t('t.ClickToNewMessage')"
      />
    </v-btn>
    <span>{{value}}</span>
  </div>
</template>

<script>
import recentsController from '@/recentsController'
import tabController from '@/tabController'

export default {
  components: {
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  inheritAttrs: false,
  methods: {
    newMessage () {
      recentsController.addTab(tabController.create(tabController.newMessageTab(), { accountContactId: this.itemId }), { documentType: 'accounts', documentId: this.$route.params.id })
    }
  },
  props: {
    value: {
      type: String
    },
    itemId: [String, Array],
    scopeModes: Array
  }
}
</script>
